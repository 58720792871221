label {
  @include theme('color', 'color-tertiary', 'label-color');

  .label-optional {
    @include theme('color', 'color-tertiary', 'label-color-optional');

    font-size: .8rem;
  }

  display: block;
  margin: 0 0 .4rem;
  font-size: .8rem;
  line-height: 1.2;
}
