$span: percentage(6 / 12);
$span-end: percentage(3 / 12);

.accolade {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: $bp-max-content;
  height: 1rem;
  margin: 0;
  border: 0;
  overflow: visible;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    width: 100%;
    height: 1rem;
    margin-top: 0;
    border-top: 2px solid;
    border-color: transparent;
    background: color('white');
    content: '';
  }

  &::before {
    left: 0;
    width: calc(#{$span});
    border-right: 2px solid;
    border-top-right-radius: border-radius('radius-4');
    border-color: transparent;
  }

  &::after {
    right: 0;
    width: calc(100% - #{$span} - 2px);
    border-left: 2px solid;
    border-top-left-radius: border-radius('radius-4');
    border-color: transparent;
  }

  //
  // Top variants.
  // Bottom variants.
  //
  &.top-left,
  &.top-center,
  &.top-right {
    top: 0;
    transform: rotate(180deg);
  }

  &.top-left,
  &.bottom-right {
    &::before {
      width: calc(100% - #{$span-end} - 2px);
    }

    &::after {
      width: calc(#{$span-end});
    }
  }

  &.top-right,
  &.bottom-left {
    &::before {
      width: calc(#{$span-end});
    }

    &::after {
      width: calc(100% - #{$span-end} - 2px);
    }
  }

  //
  // Left variants.
  // Right variants.
  //
  &.left-top,
  &.left-center,
  &.left-bottom,
  &.right-top,
  &.right-center,
  &.right-bottom {
    right: auto;
    left: 0;
    width: 1rem;
    height: 100%;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      width: 1rem;
      height: 100%;
      margin-top: 0;
      border-left: 2px solid;
      border-color: transparent;
      background: color('white');
      content: '';
    }

    &::before {
      left: 0;
      height: calc(#{$span});
      border-left: 2px solid;
      border-top-right-radius: 0;
      border-bottom-right-radius: border-radius('radius-4');
      border-color: transparent;
    }

    &::after {
      top: auto;
      bottom: 0;
      height: calc(100% - #{$span} - 2px);
      border-left: 2px solid;
      border-top-left-radius: 0;
      border-top-right-radius: border-radius('radius-4');
      border-color: transparent;
    }
  }

  &.right-top,
  &.right-center,
  &.right-bottom {
    right: 0;
    left: auto;
    transform: rotate(180deg);
  }

  &.left-top,
  &.right-bottom {
    &::before {
      height: calc(#{$span-end});
    }

    &::after {
      height: calc(100% - #{$span-end} - 2px);
    }
  }

  &.left-bottom,
  &.right-top {
    &::before {
      height: calc(100% - #{$span-end} - 2px);
    }

    &::after {
      height: calc(#{$span-end});
    }
  }
}


$span-inverse: percentage(2 / 12);
$accolade-width: 2rem;
$accolade-height: 1rem;

.accolade-inverse {
  position: absolute;
  width: $accolade-width;
  height: $accolade-height;
  background: transparent svg-as-background('accolade', color('white'), $accolade-width, $accolade-height);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  content: '';

  &.bottom-left,
  &.bottom-center,
  &.bottom-right {
    bottom: 0;
  }

  &.top-left,
  &.top-center,
  &.top-right {
    top: 0;
    transform: rotate(180deg);
  }

  &.top-left,
  &.bottom-left {
    left: calc(#{$span-inverse});
  }

  &.top-center,
  &.bottom-center {
    right: 0;
    left: 0;
    margin: auto;
  }

  &.top-right,
  &.bottom-right {
    right: calc(#{$span-inverse});
  }

  &.left-top,
  &.left-center,
  &.left-bottom {
    left: -($accolade-height/2);
    transform: rotate(90deg);
  }

  &.right-top,
  &.right-center,
  &.right-bottom {
    right: -($accolade-height/2);
    transform: rotate(-90deg);
  }

  &.right-top,
  &.left-top {
    top: calc(#{$span-inverse});
  }

  &.right-center,
  &.left-center {
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &.right-bottom,
  &.left-bottom {
    bottom: calc(#{$span-inverse});
  }
}
