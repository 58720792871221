.file {
  display: inline-flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  margin: 0 -.6rem;

  .file__button {
    @include button;
    @include button-small;
    @include theme('background-color', 'color-secondary', 'button-secondary-background');
    @include theme('color', 'color-primary', 'button-secondary-color');
    @include theme('border-color', 'color-primary--lighten-1', 'button-secondary-border-color');

    max-width: 100%;
    margin: 0 .6rem;
    padding-top: .6rem;
    padding-bottom: .6rem;
    content: attr(data-text);
  }

  &::after {
    max-width: 100%;
    margin: .6rem;
    color: color('dark-gray', -1);
    font-size: .7rem;
    font-style: italic;
    text-overflow: ellipsis;
    content: attr(data-file);
    overflow: hidden;
  }

  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;

    &:disabled {
      pointer-events: none;

      + .file__button {
        @include button-disabled;
      }
    }

    &:hover + .file__button,
    &:focus + .file__button,
    &:focus-within + .file__button {
      @include theme('background-color', 'color-primary--lighten-5', 'button-secondary-hover-background');
      @include theme('color', 'color-primary', 'button-secondary-hover-color');
      @include theme('border-color', 'color-primary--lighten-2', 'button-secondary-hover-border-color');
    }

    &:active + .file__button {
      @include theme('background-color', 'color-primary--lighten-3', 'button-secondary-focus-background');
      @include theme('color', 'color-primary--darken-3', 'button-secondary-focus-color');
      @include theme('border-color', 'color-primary', 'button-secondary-focus-border-color');

      box-shadow: none;
    }

    &:focus + .file__button {
      @include focus-bare;
    }
  }

  +.help-text {
    @include theme('color', 'color-tertiary--lighten-1', 'file-size-color');
    display: block;
    margin-top: .4rem;
    font-size: .6rem;
  }
}
