.accordion {

  .accordion--button {
    @include icon(chevron-down);
    position: relative;
    padding-right: 1.6rem;
    padding-left: 0;
    color: inherit;
    text-align: left;

    &::before {
      position: absolute;
      right: 0;
      transition: transform .2s ease-in-out;
      font-size: 1.4rem;
      line-height: 1.1;
    }

    &[aria-expanded=true] {
      &::before {
        transform: scaleY(-1);
      }
    }
  }

  .accordion--content {
    transition: max-height .5s ease-in-out;
    overflow: hidden;

    p {
      line-height: 1.75;
    }
  }

}

ul.accordion,
dl.accordion {

  .accordion--button {

    width: 100%;
    padding: 0;
    overflow: visible;

    &::before {
      position: relative;
      right: auto;
      left: 0;
      min-width: 1.6rem;
      transition: transform .2s ease-in-out;
      font-size: 1.2rem;
      line-height: 1.2;
      text-align: left;
    }

    &[aria-expanded=true] {
      @include icon(minus);

      &::before {
        transform: none;
      }
    }

    &[aria-expanded=false] {
      @include icon(plus);
    }
  }

  .accordion--content {
    margin-left: 1.6rem;
  }
}

ul.accordion {
  margin: 0;

  li {
    list-style: none;
  }
}

dl.accordion {
  dd {
    padding: 0;
  }
}
