.subscribe {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;

  label {
    width: 100%;
    max-width: 100%;
  }

  input {
    margin-bottom: .5rem;
  }

  input[type="email"] {
    flex: 1 1 $bp-mobile;
    margin-right: .4rem;
  }

  input[type="submit"] {
    @include button-large;
  }
}
