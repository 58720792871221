$month-day-size-mobile: 2rem;
$month-day-size: 2.4rem;
$week-breakpoint: '(min-width: 750px)';

.openinghours-widget {
  font-size: .8rem;

  @media (max-width: 360px) {
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }

  // ===
  // Week view
  // ===
  &[data-type*="week"] .openinghours {
    &--day-open .openinghours--status,
    &--time-prefix,
    &--times-between {
      display: none;
    }

    &--time-separator {
      font-size: 0;

      &::before {
        font-size: .8rem;
        content: '-';
      }
    }

    &--days {
      @extend %list-no-style;
    }

    &--day {
      padding: .3rem .8rem;

      &:nth-child(even) {
        @include theme('background', 'color-primary--lighten-4', 'opening-hours-even-background');
      }
    }

    &--details {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &--date {
      min-width: $bp-mobile;
    }

    &--times {
      min-width: 150px;
    }

    &--status {
      text-transform: capitalize;
    }
  }

  // ===
  // Month view
  // ===
  &[data-type="month"] {
    @include month-widget-mobile;


    @include tablet {
      @include month-widget-desktop;
    }

    > .openinghours {
      @include theme('border-color', 'color-primary--lighten-3', 'opening-hours-border');
      display: inline-block;
    }

    .openinghours {

      &--header,
      &--days {
        display: flex;
        width: $month-day-size-mobile * 7;

        @include phablet {
          width: $month-day-size * 7;
        }
      }

      // Header
      // ===
      &--header {
        justify-content: space-between;

        button {
          @include theme('color', 'color-primary--darken-2', 'opening-hours-color');

          display: flex;
          align-items: center;
          justify-content: center;
          width: $month-day-size-mobile;
          height: $month-day-size-mobile / 2;
          padding: 0;
          border: 0;
          background: transparent;
          font-size: 0;
          cursor: pointer;

          @include phablet {
            width: $month-day-size;
            height: $month-day-size / 2;
          }

          &.openinghours--prev,
          &.openinghours--next {
            &::before {
              font-size: 1.2rem;
            }
          }

          &.openinghours--prev {
            @include icon(arrow-left);
          }

          &.openinghours--next {
            @include icon(arrow-right);
          }
        }

        .openinghours--month {
          @include bold-text;
          line-height: $month-day-size-mobile / 2;

          @include phablet {
            line-height: $month-day-size / 2;
          }
        }
      }


      &--day--day-of-week {
        @include bold-text;
        text-transform: uppercase;
      }

      &--days {
        flex-wrap: wrap;
        margin: 0;
      }

      &--day {
        @extend %list-no-style;

        &,
        > span {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: $month-day-size-mobile;
          height: $month-day-size-mobile;
          border-radius: 50%;

          @include phablet {
            width: $month-day-size;
            height: $month-day-size;
          }
        }
      }

      &--day-open,
      &--day-closed {
        cursor: pointer;

        &[tabindex='0'] > span,
        &:hover > span {
          @include theme('border-color', 'color-primary--darken-2', 'opening-hours-color');
          border: 2px solid;
        }

        &[tabindex='0'] > span {
          @include bold-text;
        }

        &[tabindex='0'] .openinghours--details {
          display: block;
        }
      }

      &--day-open > span::after {
        @include theme('background-color', 'color-primary--darken-2', 'opening-hours-color');
        display: block;
        position: relative;
        bottom: .2rem;
        flex: 0 0 auto;
        width: .2rem;
        height: .2rem;
        border-radius: 50%;
        content: "";
      }

      &--day-passed > span {
        opacity: .5;
      }

      &--details {
        display: none;
        position: absolute;
        width: calc(100% - #{$month-day-size-mobile / 2});
        cursor: default;

        @include phablet {
          width: calc(100% - #{$month-day-size / 2});
        }
      }

      &--date {
        @include bold-text;
        margin-bottom: .8rem;
      }

      &--status {
        text-transform: capitalize;
      }

      &--time,
      &--times-between {
        display: inline;
      }
    }
  }
}
