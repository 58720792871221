.breadcrumb {
  clear: both;

  ul,
  ol {
    margin: 0;
    list-style: none;

    li {
      @include icon('chevron-right', 'after');

      display: inline-flex;
      align-items: center;
      margin-right: .4rem;

      &::after {
        @include theme('color', 'color-primary', 'breadcrumb-arrows-color');

        margin-left: .4rem;
        vertical-align: -15%;
      }

      &:last-of-type {
        margin-right: 0;

        &::after {
          display: none;
          content: '';
        }
      }

      > * {
        font-size: .6rem;
      }

      a {
        @include bold-text;

        &::after {
          content: none;
        }
      }

      a,
      span {
        display: inline-block;
      }
    }

    &.nav--mobile-breadcrumb {
      display: block;

      @media (min-width: $bp-tablet) {
        display: none;
      }
    }
  }

  .expandable {
    a {
      @include reset-link-background;
      @include theme('border-color', 'color-primary', 'breadcrumb-expandable-color');

      padding: .2rem .3rem .4rem;
      border: 1px solid;
      border-radius: border-radius('radius-1');
      line-height: .3rem;

      &:hover,
      &:focus {
        @include theme('background-color', 'color-primary--lighten-4', 'tag-background-color-hover');
      }
    }
  }

  &[aria-expanded="false"] {
    ul,
    ol {
      li:not(.expandable):not(:nth-last-child(-n+3)):not(:nth-child(-n+2)) {
        display: none;
      }

      @include tablet-and-below {
        display: flex;
        align-items: center;

        li:not(.expandable) {
          min-width: 1rem;

          a,
          span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        li:not(.expandable):not(:nth-last-child(-n+3)) {
          display: none;
        }
      }
    }
  }

  ///
  /// Links in the breadcrumb should never have icons.
  ///
  [class*="cs--"] & a {
    &::after {
      content: none;
    }
  }
}
