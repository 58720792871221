//
// Basic teaser theming.
//
.teaser {
  position: relative;
  list-style: none;

  &.teaser-programme, /// @deprecated
  &.teaser--inverted {
    .teaser-content {
      @include theme('background-color', 'color-secondary');
    }

    .content__top, /// @deprecated
    .content__first {
      padding: 0 .8rem;

      .figure-wrapper {
        width: calc(100% + 1.6rem);
        margin-left: -.8rem;
      }
    }

    .content__bottom, /// @deprecated
    .content__second {
      padding: 0 .8rem .8rem;
    }
  }

  &.teaser-topic, /// @deprecated
  &.teaser--underlined {
    .teaser-content {
      @include theme('border-color', 'color-primary--lighten-4', 'teaser-contact-border');

      height: 100%;
      padding-bottom: .7rem;
      border-bottom: 2px solid;
    }

    // @TODO: Move to other layer.
    .ol-popup & {
      padding-bottom: 0;
      border-bottom: 0;
    }

    .content__bottom, /// @deprecated
    .content__second {
      display: flex;
      flex-direction: column;
      align-items: stretch;


      > *:last-child {
        margin-bottom: 0;
      }
    }

    .teaser-title-link {
      margin-bottom: .8rem;
      font-size: .8rem;
      line-height: 1.2rem;
    }

    .read-more {
      margin-top: auto;
    }

    // @TODO: Move to other layer.
    .grid-3 & {
      .content__bottom, /// @deprecated
      .content__second {
        flex-grow: 1;
      }
    }
  }

  figure {
    figcaption {
      display: none;
    }
  }

  a.teaser-overlay-link {
    @include reset-link-background;
    @include element-states(transparent, transparent, background-color);

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-indent: 200%;
    white-space: nowrap;
    overflow: hidden;
    z-index: z('teaser', 'overlay-link');
  }

  a.read-more {
    @include bold-text
  }

  .teaser-content {
    display: flex;
    flex-direction: column;

    .teaser-topic-links, /// @deprecated
    .teaser-contact-info, /// @deprecated
    .teaser-links {
      @extend %list-no-style;

      margin-top: -$link-padding;
      margin-bottom: .4rem;

      a {
        font-weight: normal;
      }
    }

    .content__top, /// @deprecated
    .content__first {
      position: relative;
      order: 1;
      overflow: hidden;
    }

    .content__bottom, /// @deprecated
    .content__second {
      order: 2;
    }

    .figure-wrapper {
      position: relative;
    }

    h3 {
      margin-bottom: .4rem;
    }

    a {
      position: relative;
      z-index: z('teaser', 'content-link');
    }

    span.teaser-label {
      @include theme('background-color', 'color-primary--lighten-5', 'teaser-label-background-color');
      display: flex;
      position: absolute;
      top: .8rem;
      left: .8rem;
      align-items: center;
      padding: 0 .5em;
      border-radius: border-radius('radius-1');
      line-height: 2;
      z-index: z('teaser', 'label');

      i {
        margin-right: .2em;
        font-size: 1.1rem;
      }
    }

    p {
      margin-bottom: .4rem;
      line-height: 1.7;
    }

    div.event,
    time.published {
      display: inline-block;
      margin: 0 0 .4rem;
      line-height: 1.5;
    }

    div.event {
      @include icon('calendar');

      display: flex;
      align-items: flex-start;

      &::before {
        padding-right: .5rem;
        font-size: 1.2rem;
        line-height: 1;
      }
    }

    time.published {
      color: color('dark-gray', -1);
      font-style: italic;
      line-height: 2;
    }
  }

  .content__top, /// @deprecated
  .content__first {
    > * {
      margin-bottom: .8rem;
    }
  }
}

//
// Teaser wide variant.
//
.teaser.teaser-search, /// @deprecated
.teaser.teaser-search-wide, /// @deprecated
.teaser.teaser--wide {
  @include tablet {
    dt,
    dd {
      display: inline;
    }
  }

  @include theme('border-color', 'color-primary--lighten-4', 'view-heading-2-border-color');

  padding-top: $gutter-width;
  padding-bottom: $gutter-width;
  border-bottom: 2px solid;

  &:first-of-type {
    @include theme('border-color', 'color-primary--lighten-4', 'view-heading-2-border-color');

    margin-top: $gutter-width;
    border-top: 2px solid;
  }

  dl dd {
    margin: 0;
    padding-left: .5rem;
  }

  .tag,
  .icon-list {
    margin-bottom: .4rem;
  }

  .teaser-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include tablet {
      flex-wrap: nowrap;
    }
  }

  .accolade-inverse.bottom-left {
    display:  block;

    @include phablet {
      display: none;
    }
  }

  .accolade-inverse.right-top {
    display:  none;

    @include phablet {
      display: block;
    }
  }

  .content__left, /// @deprecated
  .content__first,
  .content__right,  /// @deprecated
  .content__second {
    flex-basis: 100%;
  }

  .content__left, /// @deprecated
  .content__first {
    order: 1;
    margin-bottom: gutter();

    @include phablet {
      flex-basis: span(5 of 18);
      margin-right: gutter();
      margin-bottom: 0;
    }
  }

  .content__right, /// @deprecated
  .content__second {
    order: 2;

    @include phablet {
      flex-basis: span(13 of 18);
    }

    .location {
      @include bold-text;

      i {
        margin-right: .3rem;
        font-size: 1.5em;
        vertical-align: middle;
      }
    }

    .links {
      margin-bottom: .4rem;

      li {
        margin-right: 0;

        &::after {
          margin-right: .4rem;
          margin-left: .4rem;
          content: '—';
        }

        &:last-of-type::after {
          content: '';
        }
      }
    }
  }
}
