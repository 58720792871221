div.responsive-table,
div.table-list {
  position: relative;
  padding-bottom: 1.4rem;

  .list-description,
  caption {
    @include theme('color', 'color-tertiary--lighten-1', 'table-caption-color');

    position: absolute;
    bottom: 0;
    width: 100%;
    margin: .4rem 0;
    font-size: .6rem;
    text-align: right;
  }
}

div.responsive-table {
  @include focus-style;

  @include tablet {
    display: inline-block;
    max-width: 100%;
  }

  display: none;

  .table-wrapper {
    overflow-x: auto;
  }


  table {
    caption-side: bottom;

    th {
      @include bold-text;

      font-size: .9rem;
      line-height: 1.7;

      &[scope="col"] {
        @include theme('border-color', 'color-primary--lighten-5', 'table-header-border-color');

        border-bottom: 2px solid;
      }
    }

    td {
      font-size: .8rem;

      &:nth-of-type(n) {
        @include table-backgrounds;
      }
    }

    tr {
      &:nth-of-type(odd) th:not([scope="col"]),
      &:nth-of-type(odd) td {
        @include theme('background-color', 'color-primary--lighten-5', 'table-row-color');
      }

      &:nth-of-type(n) {
        @include table-backgrounds(th);
        @include table-backgrounds(td);
      }
    }

    // Tables with a thead have should have the same color scheme.
    thead ~ tbody tr {
      [class*="cs--"] &:nth-of-type(odd) th:not([scope="col"]),
      [class*="cs--"] &:nth-of-type(odd) td {
        background-color: transparent;
      }

      &:nth-of-type(even) th:not([scope="col"]),
      &:nth-of-type(even) td {
        @include theme('background-color', 'color-primary--lighten-5', 'table-row-color');
      }
    }

    th,
    td {
      @include theme('color', 'color-tertiary', 'table-content-color');

      min-width: 8rem;
      padding: .3rem .2rem;
      text-align: left;
    }
  }
}

div.table-list {
  @include tablet {
    display: none;
  }

  display: block;

  &.js-hidden {
    display: none;
  }

  ul {
    @extend %list-no-style;

    li {
      padding: .6rem .3rem;
      font-size: .9rem;

      &:nth-child(2n) {
        @include theme('background-color', 'color-primary--lighten-5', 'table-list-row-color');
      }
    }
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    > dt {
      @include phablet {
        flex: 0 30%;
      }

      flex: 0 50%;
      line-height: 2.1;

      + dd {
        flex: 0 50%;

        @include phablet {
          flex: 0 70%;
        }
      }
    }

    > dd {
      flex: 0 100%;
      margin: 0;
      padding: 0;
      font-size: .8rem;
      line-height: 2.4;
    }
  }
}
