.cta-block {

  &.link .inner-box::before {
    @include spot-image('link-light', 7rem);
  }

  &.download .inner-box::before {
    @include spot-image('download-light', 7rem);
  }

  .inner-box {

    min-height: 11rem;

    li {
      width: 100%;
      margin-top: .8rem;

      &:first-of-type a {
        @extend %button-primary;
        @include button;
        @include button-icon;
      }

      &:last-of-type {
        @include theme('border-color', 'color-primary--lighten-4', 'more-info-block-border-color');

        padding-top: .8rem;
        border-top: 2px solid;
      }
    }
  }

  &--multiple {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: -1rem;
    margin-left: -1rem;

    > .cta-block {
      flex: 1 0 20rem;
      max-width: calc(100% - 2rem);
      margin-right: 1rem;
      margin-left: 1rem;

      .inner-box {
        height: 100%;

        @include tablet {
          padding: 5rem 2rem 2rem;

          &::before {
            top: 0;
            right: 0;
            left: 0;
          }
        }
      }
    }
  }
}
