.file-upload {

  ul.inline {
    margin-top: .8rem;

    li {
      margin-right: .4rem;
      margin-bottom: .4rem;
    }
  }

  .button {
    margin-bottom: .8rem;
  }
}
