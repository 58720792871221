h1,
h2,
h3,
h4,
h5,
h6 {
  &.collection-title {
    @extend %h2;
    @include theme('border-color', 'color-primary--lighten-4', 'block-title-border-color');

    display: block;
    position: relative;
    max-width: 100%;
    margin-bottom: 1.6rem;
    padding-bottom: .4rem;
    border-bottom: 2px solid;

    span {
      display: inline-block;
      position: relative;
      min-width: 5rem;
      margin-bottom: -1.4rem;
      padding: inherit;
      padding-bottom: 1.4rem;
      background-color: color('white');
      font-size: inherit;

      &::before,
      &::after {
        @include theme('border-color', 'color-primary', 'block-title-accolade-color');
        display: block;
        position: absolute;
        bottom: 0;
        height: 1rem;
        border-width: 2px;
        content: '';
      }

      &::before {
        left: 0;
        width: 2.5rem;
        border-top-style: solid;
        border-right-style: solid;
        border-top-right-radius: border-radius(radius-3);
      }

      &::after {
        right: 0;
        width: calc(100% - 2.5rem + 2px);
        border-top-style: solid;
        border-left-style: solid;
        border-top-left-radius: border-radius(radius-3);
      }
    }
  }
}
