// mixins
// ===
@mixin month-widget-mobile {
  min-width: initial;
  padding-right: 0;
  padding-bottom: 6rem;

  > .openinghours {
    padding-right: 0;
    padding-bottom: $month-day-size-mobile / 2;
    border: 0;
  }

  .openinghours--details {
    @include theme('border-color', 'color-primary--lighten-3', 'opening-hours-border');

    top: calc(100% + #{$month-day-size-mobile / 2});
    left: $month-day-size-mobile / 4;
    padding-top: $month-day-size-mobile / 2;
    border-top: 2px solid;
  }
}

@mixin month-widget-desktop {
  padding-right: $month-day-size * 7 - $month-day-size;
  padding-bottom: 0;

  > .openinghours {
    @include theme('border-color', 'color-primary--lighten-3', 'opening-hours-border');

    padding-right: $month-day-size / 2;
    padding-bottom: 0;
    border-right: 2px solid;
  }

  .openinghours--details {

    top: -$month-day-size / 2;
    left: calc(100% + #{$month-day-size});
    padding-top: 0;
    border-top: 0;
  }
}
