.opening-hours-accordion {
  // children
  &__item {
    & + & {
      margin-top: 1.2rem;
    }
  }

  .opening-hours-wrapper {
    margin-bottom: .5rem;
  }

  .accordion {
    &--content {
      margin-bottom: 1.2rem;
    }

    &--button {
      font-size: .9rem;
    }
  }
}
