.search {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;

  label {
    @extend %h3;

    width: 100%;
    max-width: 100%;
  }

  input {
    margin-bottom: .5rem;

    &:not([type="submit"]) {
      flex: 1 1 $bp-mobile;
      margin-right: .4rem;
    }
  }

  input[type="search"] {
    @extend %search-input;
  }

  input[type="submit"] {
    @include button-large;
    margin-right: 0;
  }
}

%search-input {
  padding-left: 2.4rem;

  &,
  &:focus {
    background: svg-as-background('search', color('cyan-wcag'), 32, 32) no-repeat left .4rem center;
  }
}
