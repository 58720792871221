.quote-wrapper {
  overflow: auto;
}

.quote {
  @include theme('border-color', 'color-primary--lighten-4', 'quote-border-color');
  margin-top: 3.5rem;
  border-top: 2px solid;

  @include desktop {
    margin-top: 0;
  }
}

blockquote {
  @include bold-text;

  display: flex;
  position: relative;
  flex-direction: column;
  font-size: .8rem;
  text-align: center;

  @include desktop {
    padding-top: 1.5rem;
    padding-left: 11.2rem;
    font-size: .9rem;
    text-align: left;
  }

  &::before {
    @include spot-image('quote', 7rem);
    margin: -3.5rem auto .8rem;

    @include desktop {
      position: absolute;
      top: 1.5rem;
      left: 1rem;
      margin: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    @include desktop {
      min-height: 7.8rem;
    }
  }

  p {
    &:first-of-type::before {
      content: '\201C';
    }

    &:last-of-type::after {
      content: '\201D';
    }
  }


  footer {
    position: relative;
    padding-top: 1.6rem;
    font-style: italic;
    font-weight: 500;
    text-align: center;

    &::before {
      @include theme('background-image', 'accolade-stroke-light', 'quote-accolade-stroke');

      display: block;
      position: absolute;
      top: -.1rem;
      right: 0;
      left: 0;
      height: 1.1rem;
      background-repeat: no-repeat;
      background-position: center bottom;
      content: '';
    }
  }
}
