.form-row {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + .8rem);
  max-width: 21.3rem;
  margin-right: -.4rem;
  margin-left: -.4rem;


  @include desktop {
    width: calc(50% + .8rem);
  }

  > * {
    flex-basis: calc((21.3rem - 100%) * 999);
    flex-grow: 1;
    margin-right: .4rem;
    margin-left: .4rem;
  }
}
