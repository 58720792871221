$breakpoint-openinghours: '(min-width: 750px)';

// ===
// Day view
// ===
.openinghours-widget {
  &[data-type="day"] {
    display: inline-block;
    border-radius: border-radius('radius-1');
    overflow: hidden;

    > * {
      line-height: 1.4rem;
    }

    .channel-label {
      @include bold-text;

      float: left;
      font-size: 0;

      &::after {
        display: inline-block;
        content: " ";
      }

      &:nth-of-type(n + 2) {
        padding-left: 1.9rem;
        font-size: .8rem;

        + .openinghours {
          padding-top: 0;

          &::before {
            display: none;
          }

          .openinghours--date,
          .openinghours--status {
            display: none;
          }

        }
      }
    }

    .openinghours {
      margin-top: -.6rem;
      padding: .3rem .5rem .3rem calc(.5rem + 1.5em);
      background-color: color('green', -4);

      &::before {
        margin: 0 0 0 -1.2em;
        font-size: 1.5em;
        vertical-align: -25%;
      }

      &:first-of-type {
        @include icon(clock);
        margin-top: 0;
      }

      &--day-closed {
        background-color: color('red', -4);
      }

      & + .openinghours--day-closed {
        display: none;
      }

      &--date,
      &--content,
      &--times,
      &--times-between,
      &--time {
        display: inline;
      }

      &--time time:last-of-type {
        &::before {
          content: "-";
        }
      }

      &--date,
      &--status {
        @include bold-text;
      }

      &--special-day {
        > span {
          display: none;
        }

        > span:first-child {
          display: inline;
        }
      }

      &--time-prefix,
      &--time-separator {
        display: none;
      }
    }
  }
}
