///
/// The container to put a grid in.
///
/// @since 3.0.0
/// @group grid
/// @access public
/// @author Gert-Jan Meire
///
%container,
.container {
  @include clearfix();
  @include breakpoint($bp-container) {
    width: $bp-container;
    max-width: 100%;
    margin: 0 auto;
  }

  padding: 0 1rem;
  overflow: hidden;
}

///
/// The inner container to put a grid in. This also provides the necessary
/// padding for mobile display.
///
/// @since 3.0.0
/// @group grid
/// @access  public
/// @author Gert-Jan Meire
///
%content-container,
.content-container {
  max-width: $bp-max-content;
  margin: 0 auto;
}

