// General footer styling.
.footer {
  margin-top: 4rem;

  .footer-top {
    @include theme('background-color', 'color-primary--lighten-5', 'footer-background-color');

    width: calc(100% + 2 * #{$gutter-width});
    margin-right: -$gutter-width;
    margin-left: -$gutter-width;

    > .content-container {
      display: flex;
      flex-wrap: wrap;
      max-width: calc(#{$bp-max-content} + 54px);
      margin-bottom: 1rem;
      padding: 0 $gutter-width 1.5rem;

      @include desktop {
        padding-top: 4rem;
        padding-bottom: 4rem;
      }

      @media (min-width: $bp-max-content + 54px) {
        max-width: $bp-max-content;
        padding: 4rem 0;
      }
    }

    .footer--column {
      width: 100%;

      @include phablet {
        flex: 1 0 auto;
        width: span(6 of 12);
      }

      @include desktop {
        width: span(2 of 12);
      }

      &:nth-of-type(3) {
        display: flex;

        @include desktop {
          justify-content: center;
          width: span(2 of 12);
        }
      }

      &:first-of-type {
        width: 100%;

        @include phablet {
          width: span(12 of 12);
          margin-top: 1.2rem;
          margin-bottom: .4rem;
        }

        @include desktop {
          order: 1;
          width: span(7 of 12);
          margin-top: 0;
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: .8rem;
    overflow: hidden;

    @include desktop {
      flex-wrap: nowrap;
    }

    .footer--column {
      width: 100%;
      line-height: 1rem;

      // Left column.
      &:first-child {
        order: 2;

        @include desktop {
          order: 1;
          width: span(4 of 12);
        }
      }

      // Right column.
      &:last-child {
        order: 1;
        margin-bottom: 1.8rem;

        @include phablet {
          margin-bottom: 0;
        }

        @include desktop {
          width: span(8 of 12);
        }
      }
    }
  }

  .dg-block-post-address,
  .dg-block-disclaimer {
    margin-top: 2.2rem;
    margin-left: 1.2rem;

    @include desktop {
      margin-left: 0;
    }
  }

  .dg-blocks-social,
  .db-block-partnership {
    height: 2rem;

    @include phablet {
      margin-bottom: .4rem;
    }
  }

  .db-block-partnership {
    justify-content: center;
    width: 100%;

    @include desktop {
      justify-content: flex-start;
    }
  }

  ///
  /// Links in the footer should never have icons.
  ///
  [class*="cs--"] & a {
    &::after {
      content: none;
    }
  }
}
