a.site-logo {
  @include reset-link-background;
  @include theme('color', 'color-tertiary', 'default-text-color');
  display: inline-flex;
  align-items: center;
  font-size: 0;

  &::before {
    @include spot-image('logo', 4rem);
    min-width: 4rem;
    height: 3rem;
  }

  &:hover,
  &:focus {
    background-color: transparent;
  }

  &.subsite {
    @include tablet {
      font-size: .9rem;
    }

    &::before {
      @include tablet {
        margin-right: .7rem;
      }
    }
  }
}
