////
/// Default markup for the most common HTML elements, based on the settings file.
////

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  background: color('white');
  font: $default-font-weight $default-font-size $default-font-family;
  line-height: $default-line-height;
  box-sizing: border-box;
}

body {
  // Since the `cs--` class is set on the body element, the styles should be applied directly to the element.
  @each $theme, $colors in $themes {
    &.cs--#{$theme} {
      color: set-themified-property($colors, 'color-tertiary', 'default-text-color');
    }
  }

  position: relative;
  font-size: .8rem;
}

::selection {
  @include theme('background-color', 'color-primary--darken-1', 'text-selection-background');
  @include theme('color', 'color-secondary', 'text-selection-color');
}

th > * {
  margin: 0;
}

hr {
  margin: 0 auto 1.4rem;
  clear: both;
  border: solid color('dark-gray', -2);
  border-width: 1px 0 0;
}

//
// Fix a > img problem
//
img {
  border: 0;
  vertical-align: top;
}

p,
ol,
ul,
dl,
table,
address,
pre {
  margin-bottom: .5em;
  font-size: inherit;
  line-height: inherit;
}

p {
  margin-bottom: 1em;
}

address {
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
p,
span,
input,
label,
textarea,
button,
select,
dd,
q {
  max-width: 40rem;
}

strong {
  @include bold-text;
}

input[type=button],
button {
  font: $default-font-weight $default-font-size $default-font-family;
}

a[href],
area[href],
input:not([disabled]),
select:not([disabled]),
textarea:not([disabled]),
button:not([disabled]),
object,
embed,
iframe,
*[tabindex]:not([tabindex="-1"]),
*[contenteditable] {
  @include focus-style;
}
