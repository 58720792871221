.tag {
  @include theme('border-color', 'color-primary--lighten-3', 'tag-span-border-color');
  @include theme('background-color', 'color-secondary', 'tag-background-color');

  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: .7em .8em;
  border: 1px solid;
  border-radius: border-radius('radius-1');
  font-size: .7rem;
  line-height: 1.1;
  text-decoration: none;

  &.filter {
    @include theme('background-color', 'color-primary--lighten-4', 'tag-filter-background-color');

    padding: .4em .8em .4em 1.2em;
    border-color: transparent;

    &:hover button {
      @include theme('background-color', 'color-primary--lighten-4', 'tag-close-button-background-hover');
    }

    button {
      width: 1.1rem;
      height: 1.1rem;
      margin-left: .5rem;
      padding: 0;
      transition: background-color .2s ease-in-out;
      border: 0;
      border-radius: 100%;
      background: transparent svg-as-background('cross', color('dark-gray'), 16, 16);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
  }
}

a.tag {
  @include theme('border-color', 'color-primary--lighten-2', 'tag-border-color');
  @include icon(arrow-right, after);

  padding: .4em .8em;
  border-width: 2px;

  &::after {
    width: 1.1rem;
    height: 1.1rem;
    margin-left: .5rem;
    font-size: 1rem;
    vertical-align: middle;
  }

  &,
  &:focus,
  &:hover {
    background-image: none;
  }

  &:hover {
    @include theme('background-color', 'color-primary--lighten-4', 'tag-background-color-hover');
    @include theme('border-color', 'color-primary--lighten-2', 'tag-border-color-hover');
  }
}
