.fieldset-wrapper fieldset,
fieldset  {
  @include theme('border-color', 'color-primary--lighten-4', 'fieldset-border-color');

  padding-top: .8rem;
  border-top: 1px solid;

  &,
  &.form-item {
    margin-bottom: 0;
    border-bottom: 0;
  }

  &:only-of-type,
  &:last-of-type {
    @include theme('border-color', 'color-primary--lighten-4', 'fieldset-border-color');

    margin-bottom: 1rem;
    border-bottom: 1px solid;
  }

  legend {
    @include bold-text;

    padding: 0 .2rem 0 0;
    font-size: .9rem;
    line-height: 2;

    + .field-message {
      margin-top: -1rem;
      margin-bottom: 1rem;
    }
  }

  fieldset {
    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }

    legend {
      margin-left: 1.8rem;
      padding: 0 .2rem;
    }
  }
}
