.filter {
  .filter-section {
    padding-top: .7rem;
    visibility: hidden;

    @include desktop {
      position: static;
      height: auto;
      box-shadow: none;
      overflow: visible;
      visibility: visible;
      z-index: auto;
    }

    &.visible {
      @include breakpoint(max-width $bp-desktop) {
        padding: 0;
        visibility: visible;

        > .modal-inner > .modal-header {
          display: block;
        }
      }
    }

    > .modal-inner {
      width: 100%;
      max-width: initial;
      min-height: 100%;
      margin: 0;
      padding: 0;

      @include desktop {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        z-index: auto;
        filter: none;
      }

      > .modal-header {
        display: none;
      }

      > .modal-content {
        @include desktop {
          padding: 0;
          overflow: visible;
        }
      }

      > .modal-actions {
        @include desktop {
          padding-right: 0;
          padding-left: 0;
          border: 0;
        }
      }
    }

    > .modal-overlay {
      display: none;
    }

    form {
      // style first level labels as headings
      > .form-item > label,
      > fieldset.form-item > legend {
        @extend %h3;
      }

      > fieldset {
        padding: 0;
        border: 0;
      }
    }

    .label-optional {
      display: none;
    }

    .filter__submit {
      margin-top: .8rem;
      margin-bottom: .8rem;
    }
  }

  .result-section {

    .selected-filters,
    .filter__result-count {
      h2 {
        font-size: .8rem;
      }
    }

    .result__show-filters {
      @include desktop {
        display: none;
      }
    }

    .selected-filters {
      margin-bottom: 1rem;

      .tag {
        margin: 0 .5rem .5rem 0;
      }

      a {
        white-space: nowrap;
      }
    }

    .filter__result-count {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.2rem;

      > * {
        margin: 0;
      }

      > * + * {
        margin-left: gutter() / 2;
      }
    }

    ul.filter__results {
      @extend %list-no-style;
    }

    .pager {
      @include tablet {
        li.previous,
        li.next {
          display: none;
        }
      }

      @include desktop {
        li.previous,
        li.next {
          display: block;
        }
      }
    }
  }
}
