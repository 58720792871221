.programme {
  > .inner-box, /// @deprecated
  > .highlight__inner {
    padding: 4.8rem 2rem 4rem;

    &::before {
      @include spot-image('calendar');

      @include tablet {
        right: 0;
        left: 0;
      }
    }
  }

  .image-wrapper {
    background-color: color('cyan', -4);
  }

  .read-more {
    font-size: .8rem;
    line-height: normal;
  }

  ///
  /// Programme slot.
  ///
  &-slot {
    width: 100%;
    max-width: $bp-max-content;
    margin: 0 auto;

    &-title {
      margin: 0 auto 1.6rem;
      font-size: 1.3rem;
      text-align: center;
    }

    > dd {
      max-width: none;
      padding: 0;
    }

    & + & {
      margin-top: 2.8rem;
    }
  }

  ///
  /// Programme detail
  ///
  &-detail {
    h3 {
      @extend %h2;
    }

    h4 {
      @extend %h3;
      margin-bottom: .4rem;
    }

    ul {
      font-size: .8rem;
      list-style: disc;
    }

    .image-gallery--wrapper {
      margin: 1rem 0;

      .show-more__content {
        text-align: center;
      }

      @include desktop-and-below {
        .image-gallery,
        .gallery-link {
          max-width: none;
        }

        .gallery-link {
          width: 100%;
          margin: 0;
        }
      }
    }

    .video {
      margin-top: 1.6rem;

      > .responsive-video {
        width: calc(100% + 2rem);
        margin-left: -1rem;

        @include tablet {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}
