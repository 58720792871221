nav.menu {
  margin: 0;

  button {
    @include button-icon;
    padding: 0;
    border: 0;
    background: transparent;
    font-size: .8rem;

    @include tablet {
      display: none;
    }

    &::before {
      order: 0;
      margin-right: .5rem;
      margin-left: 0;
    }

    &.toggle {
      @include icon(hamburger);
    }

    &.close {
      @include icon(cross);
    }
  }

  ul {
    @extend %list-no-style;
    @include tablet-and-below {
      display: none;
    }

    margin: 0;

    li {
      display: inline-block;
      margin-right: 1rem;

      a,
      a[href^="mailto:"],
      a[download],
      a[href^="http://"],
      a[href^="https://"] {
        @include reset-link-background;
        @include link-underlined;

        margin-bottom: 0;
        padding: .5rem 0;
      }
    }
  }
}

.modal.menu {
  @include tablet {
    display: none;
  }

  .modal-inner {
    .modal-header {
      position: absolute;
      right: 0;
      margin-top: 1rem;
      margin-right: .5rem;
      z-index: 7;
    }
  }

  .modal-content {
    flex: 1;
    padding: 0;

    .header {
      position: relative;
      margin: .6rem $gutter-width 1.6rem;

      &::before,
      &::after {
        @include theme('border-color', 'color-primary--lighten-4', 'header-border-bottom');

        position: absolute;
        top: 100%;
        width: 100%;
        height: 1rem;
        margin-top: .6rem;
        border-top: 2px solid;
        content: '';
      }

      &::before {
        left: -$gutter-width;
        width: 3rem + $gutter-width;
        border-right: 2px solid;
        border-top-right-radius: border-radius('radius-4');
      }

      &::after {
        right: -$gutter-width;
        // viewport - ::before + border-width + gutter-width
        width: calc(100% - 3rem + 2px + #{$gutter-width});
        border-left: 2px solid;
        border-top-left-radius: border-radius('radius-4');
      }

      button {
        position: absolute;
        right: 0;
        margin-top: .25rem;
      }
    }

    .menu-links {
      ul {
        @extend %list-no-style;
        margin: 0;

        li {
          @include tablet {
            display: inline-block;
            margin-right: 1rem;
          }

          margin-bottom: .8rem;

          &:first-child {
            margin-top: .8rem;
          }

          a,
          a[href^="mailto:"],
          a[download],
          a[href^="http://"],
          a[href^="https://"] {
            @include reset-link-background;
            @include link-underlined;

            padding: .5rem 0;
            line-height: 1.4rem;
          }
        }
      }
    }

    .mijn-gent-block {
      padding: 1.2rem 0;

      .content {
        padding: 0;
      }
    }

    .language-switcher {
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        padding: .6rem 0;

        li {
          padding: .6rem;
        }
      }
    }

    .mijn-gent-block,
    .language-switcher {
      display: block;
    }

    .menu-links,
    .mijn-gent-block,
    .language-switcher {
      margin: 0 1.2rem;

      &:not(:last-child) {
        @include theme('border-color', 'color-primary--lighten-4', 'mijn_gent-border-color');
        border-bottom: 2px solid;
      }
    }
  }
}
