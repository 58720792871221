.detail-layout {
  @extend %content-container;

  margin-top: 1.2rem;
  font-size: .9rem;
  line-height: 1.8;

  // Give each direct child a specific width.
  // This is a fallback width given to all children of the detail layout.
  > * {
    @include desktop {
      @include set-layout('width-7', 'offset-2');
    }
  }

  h1 {
    width: 100%;

    + .tag {
      display: inline-block;
      width: auto;
      margin-top: -.4rem;
      margin-bottom: 2rem;
    }
  }

  > div,
  > section,
  > aside,
  > article {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  > ul,
  > ol {
    padding-left: 2rem;
  }

  > .help-block,
  > .search-block {
    @include desktop {
      @include set-layout('width-9', 'offset-1');
    }
  }

  > .box-top,
  > .summary-box,
  > .cta-block,
  > .contact-block,
  > .contact-details,
  > .highlight,
  > .image-gallery--wrapper.multiple,
  > .contact-box {
    @include desktop {
      @include set-layout('width-10', 'offset-1');
    }
  }

  > ul.accordion {
    @include desktop {
      @include set-layout('width-7', 'offset-2');
    }

    .accordion--content p {
      line-height: 1.75;
    }
  }

  > .help-block {
    @include desktop {
      padding-left: span(1 wide of 12);
    }
  }

  > .quote-wrapper {
    @include desktop {
      @include set-layout();

      > .quote {
        @include set-layout('width-9', 'offset-1');
      }
    }
  }

  > .timeline-wrapper,
  > .programme-wrapper {
    @include set-layout();
    @include extra-wide;
  }

  .readspeaker-button {
    display: block;
    width: auto;
    margin-top: -.4rem;
    margin-bottom: 2rem;
  }

  .summary-text {
    @include bold-text;
  }

  .openinghours-widget[data-type="month"] {
    @include desktop {
      @include month-widget-mobile;
    }

    @include breakpoint(1200px) {
      @include month-widget-desktop;
    }
  }

  > .full-width {
    @include full-width;
  }

  > .highlight--top,
  > .box-top { /// @deprecated
    .highlight__inner,
    .inner-box { /// @deprecated
      @include desktop {
        padding-right: 0;
        padding-left: 0;

        > *:not(.accolade) {
          width: span(7 of 10);
          margin-left: span(1 wide of 10);
        }
      }
    }
  }

}

$bulb-image-width: 6rem;

aside.top,
aside.bottom {
  @extend %content-container;

  > * {
    @include theme('border-color', 'color-primary--lighten-4', 'more-info-block-border-color');

    padding-top: 3rem;
    border-top: 2px solid;

    @include tablet {
      padding-top: 2rem;
    }

    @include desktop {
      @include set-layout();
      width: 100%;
      margin-bottom: 2rem;
      margin-left: 0;
      padding-right: span(2 wide of 12);
      padding-left: span(2 wide of 12);
    }
  }

  > p {
    padding-top: 0;
    border-top: 0;
  }
}

aside.top {
  position: relative;
  margin: 5rem auto 0;

  > div:first-child {
    &::before {
      @include spot-image('lightbulb', $bulb-image-width);

      display: block;
      position: relative;
      margin: (-$bulb-image-width) auto 1rem;
      z-index: z('default');

      @include tablet {
        position: absolute;
        top: -($bulb-image-width/2);
        right: span(1 of 12);
        margin: 0;
      }
    }
  }
}

aside.bottom {
  .changed-date {
    @include desktop {
      @include set-layout('width-9', 'offset-2');
    }

    padding: 0;
  }
}
