.gentinfo-block {
  @include theme('background-color', 'color-secondary', 'gentinfo-block-background');
  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin-top: 3rem;
  padding: 6rem 1.2rem 2.5rem;
  font-size: .8rem;
  line-height: 1.75;
  text-align: center;
  box-shadow: 0 8px 12px 0 rgba(0, 125, 179, .06);

  @include phablet {
    flex-wrap: nowrap;
    margin: 0;
    padding: 2.5rem;
    text-align: left;
  }

  &::before {
    @include spot-image('gentinfo');
    height: 10rem;
    margin: -6rem auto 0;

    @include phablet {
      display: block;
      align-self: center;
      margin: 0 2.4rem 0 0;
    }
  }

  .gentinfo-block-content {
    width: 100%;
    text-align: left;

    @include phablet {
      width: span(5 of 7);
      max-width: 15rem;
    }

    h2,
    p {
      text-align: center;

      @include phablet {
        text-align: left;
      }
    }

    h2 {
      font-weight: 800;
    }

    ul {
      margin: 0;
      list-style: none;

      li {
        margin-bottom: .5rem;
      }

      a {
        @include bold-text;
      }
    }
  }

  .gentinfo-chat-button {
    position: absolute;
    right: 1.2rem;
    bottom: -1rem;
    left: 1.2rem;
    width: calc(100% - 2.2rem);
    margin: auto;

    @include phablet {
      right: 1.5rem;
      left: auto;
      width: auto;
    }
  }

  .gentinfo-logo {
    display: block;
    width: 8.25rem;
    margin: 0 auto 1.5rem;

    @include phablet {
      margin: 0 0 1.5rem;
    }
  }
}
