h1,
.h1,
%h1,
h2,
.h2,
%h2,
h3,
.h3,
%h3,
h4,
.h4,
%h4,
h5,
.h5,
%h5 {
  @include bold-text;

  margin: 0 0 .8rem;
  font-style: normal;
  line-height: 1.5;

  button {
    display: flex;
    padding: 0;
    border: 0;
    background: none;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    cursor: pointer;

    i {
      font-size: inherit;
    }
  }
}
