.readspeaker-button {
  @include theme('color', 'color-tertiary--lighten-1', 'readspeaker-color');
  display: inline-block;
  min-height: 1.8rem;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:focus,
  &:hover {
    i {
      @include theme('background-color', 'color-primary', 'readspeaker-icon-color');

      [class*='cs--'] & {
        color: color('white');
      }
    }
  }

  i {
    @include theme('color', 'color-primary', 'readspeaker-icon-color');
    @include theme('border-color', 'color-primary', 'readspeaker-icon-border-color');

    display: inline-block;
    margin-right: .3rem;
    padding: .1em .3em;
    transition: background-color .2s ease-in-out, color .2s ease-in-out;
    border: 2px solid;
    border-radius: border-radius('radius-1');
    font-size: .9rem;
    vertical-align: middle;
  }

  span {
    font-size: .6rem;
    vertical-align: middle;
  }
}
