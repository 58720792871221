ul.image-wall,
ul.image-gallery {
  list-style: none;

  figcaption {
    display: none;
  }

  a.gallery-link {
    @include reset-link-background;
    display: block;
    margin: 0;
    padding: 0;
    font-weight: normal;
    text-decoration: none;

    &::after {
      content: none;
    }

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

ul.image-wall {
  margin: 0;
}

ul.image-gallery {
  margin: 0 calc(-1 * (#{$gutter-width} / 2));
}
