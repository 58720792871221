.extra-articles {
  &-content {
    > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0;
      padding-top: .8rem;

      li {
        @include phablet {
          width: span(4 of 8);
        }

        width: span(12 of 12);
        margin-bottom: 1.6rem;
      }
    }
  }
}
