.dg-blocks-social {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 2rem;
  margin-bottom: 1rem;

  @include tablet {
    flex-wrap: nowrap;
    margin-bottom: 0;
  }

  @include desktop {
    justify-content: flex-end;
  }

  > span,
  > div {
    margin-bottom: 1.2rem;
    font-size: .7rem;
    line-height: 1rem;

    @include phablet {
      margin-bottom: 0;
    }
  }

  > span {
    flex-basis: 100%;
    text-align: center;

    @include phablet {
      flex-basis: auto;
      text-align: right;
    }

    @include tablet {
      margin-right: 1.4rem;
    }
  }
}
