.theme-footer {
  @include theme('background-color', 'color-primary--lighten-5', 'theme-footer-background-color');
  @include theme('border-color', 'color-primary--lighten-4', 'theme-footer-border-bottom-color');

  width: calc(100% + 2rem);
  margin-right: -1rem;
  margin-left: -1rem;
  padding: 0;
  border-bottom: 2px solid;

  + .footer {
    margin-top: 0;
  }

  .contact,
  .newsletter,
  .social,
  .logo {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;

    @include tablet {
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
    }
  }

  .logo {
    flex-shrink: 0;
    text-align: center;

    @include tablet {
      margin-right: 2rem;
      text-align: left;
    }

    img {
      @include desktop-and-below {
        margin-right: auto;
        margin-left: auto;
      }

      width: auto;
      height: 8rem;
    }

    + .newsletter,
    + .social,
    + .contact {
      padding-top: 0;

      @include tablet {
        padding-top: 2.4rem;
      }
    }
  }

  .contact {
    margin-right: auto;

    @include desktop {
      padding-right: 2rem;
    }

    .icon-list {
      margin: 0;

      li:last-of-type {
        margin-bottom: 0;
      }

      @include desktop {
        column-gap: gutter();
        column-count: 2;
      }
    }

    &:only-child .icon-list {
      @include tablet {
        column-gap: gutter();
        column-count: 2;
      }
    }
  }

  .social {
    @include tablet {
      flex-shrink: 0;
      width: $bp-mobile;
    }

    @include tablet {
      &:only-child {
        width: auto;
        text-align: center;
      }
    }

    h3 {
      max-width: 100%;
    }

    .social-list {
      ul {
        width: calc(100% + 1rem);
        margin: 0 -.5rem;

        a {
          &,
          &[download]:not(.button),
          &[href^="http://"]:not(.button),
          &[href^="https://"]:not(.button) {
            @include theme('background-color', 'color-secondary', 'theme-footer-social-media-icon-background');
          }
        }
      }
    }
  }

  .newsletter {
    flex-grow: 1;
    border-radius: 0;
    font-size: .8rem;

    @include desktop {
      padding-right: 2rem;
    }

    .inner-box, ///@deprecated
    .highlight__inner {
      padding: 9.4rem 0 0;
      background-color: transparent;

      &::before {
        @include spot-image('letter-light', 8rem);
        top: 1.4rem;
      }

      @include tablet {
        min-height: 8rem;
        padding-top: 0;
        padding-left: 10rem;

        &::before {
          top: calc(50% - 4rem);
          right: auto;
          left: 0;
        }
      }
    }

    .subscribe {
      // 5/12 of max content-container width
      max-width: calc(#{$bp-max-content} / 12 * 5);
    }
  }

  .logo + .newsletter {
    .inner-box, /// @deprecated
    .highlight__inner {
      padding-top: 0;

      &::before {
        display: none;
      }

      @include tablet {
        padding-left: 0;
      }
    }
  }

  .content-container {
    padding: 0 $gutter-width;

    @media (min-width: $bp-max-content + 54px) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .top {
    @include theme('border-color', 'color-primary--lighten-4', 'theme-footer-border-bottom-color');
    border-bottom: 2px solid;
  }

  .top,
  .bottom {
    @include tablet {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    @include desktop {
      flex-wrap: nowrap;
    }

    .newsletter,
    .contact {
      &:not(:last-of-type) {
        @include theme('border-color', 'color-primary--lighten-4', 'theme-footer-border-bottom-color');
        border-bottom: 2px solid;

        @include desktop {
          border-bottom: 0;
        }
      }
    }
  }

  ///
  /// External links other than group Ghent get an indicator.
  ///
  a {
    @extend %a-remove-indicator;
  }
}
