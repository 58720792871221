ul.grid-2,
ul.grid-3,
ul.grid-4,
ul.grid-5 {
  @extend %list-no-style;

  display: flex;
  flex-wrap: wrap;
  width: calc(100% + #{gutter()});
  margin: 0 -#{gutter()/2} -1.2rem;

  > li {
    flex-basis: 100%;
    flex-shrink: 1;
    margin-bottom: 1.2rem;
    padding: 0 gutter()/2;
  }
}

.grid__show-more {
  margin: 1.2rem 0;
  font-size: .9rem;
  text-align: center;
}

ul.grid-5 {
  > li {
    @include phablet {
      flex-basis: 50%;
      max-width: 50%;
    }

    @include tablet {
      flex-basis: 25%;
      max-width: 25%;
    }

    @include desktop {
      flex-basis: 20%;
      max-width: 20%;
    }
  }
}

ul.grid-4 {
  > li {
    @include phablet {
      flex-basis: 50%;
      max-width: 50%;
    }

    @include tablet {
      flex-basis: 33.3333%;
      max-width: 33.3333%;
    }

    @include desktop {
      flex-basis: 25%;
      max-width: 25%;
    }
  }
}

ul.grid-3 {
  > li {
    @include phablet {
      flex-basis: 50%;
      max-width: 50%;
    }

    @include desktop {
      flex-basis: 33.3333%;
      max-width: 33.3333%;
    }
  }
}

ul.grid-2 {
  > li {
    @include phablet {
      flex-basis: 50%;
      max-width: 50%;
    }
  }
}
