.field-message {
  @include theme("color", "color-tertiary--lighten-1", "field-messages-color");
  @include theme(
    "background-color",
    "color-primary--lighten-4",
    "field-description-background"
  );

  $field-message-icon-size: 1.5rem;
  $field-message-icon-margin: 1.5rem;

  display: inline-block;
  position: relative;
  align-items: center;
  max-width: 40rem;
  min-height: 2.5rem;
  padding: 1.7rem 1.2rem .7rem;
  border-radius: border-radius("radius-1");
  font-size: .8rem;
  line-height: 1.43;
  overflow: hidden;

  &::before {
    display: block;
    position: absolute;
    top: 1.5rem;
    left: .7rem;
    width: $field-message-icon-size;
    height: $field-message-icon-size;
    margin: auto $field-message-icon-margin auto auto;
    border-radius: 100%;
    background-color: color("white");
    font-size: 1.1rem;
    line-height: $field-message-icon-size;
    text-align: center;
  }

  .accolade {
    top: 0;
    transform: rotate(180deg);

    &::before {
      left: -1px;
      width: calc(100% - 1.5rem + 1px);
    }

    &::after {
      right: -1px;
      width: calc(1.5rem + 1px);
    }
  }

  &.success {
    @include icon("checkmark");

    padding-left: 2.9rem;
    background-color: color("green", -4);
  }

  &.error {
    @include icon("cross");

    padding-left: 2.9rem;
    background-color: color("red", -4);
  }

  a {
    color: inherit;
  }
}
