///
/// Helper mixin to break out of the grid structure in targeted places.
///
/// @since 3.0.0
/// @group grid
/// @access  public
/// @author Gert-Jan Meire
///
@mixin extra-wide {
  @include desktop-and-below {
    width: calc(100% + (#{$gutter-width} * 2));
    margin-right: -$gutter-width;
    margin-left: -$gutter-width;
  }
}

///
/// Provide a helper mixin to easily position items on the grid..
///
/// @since 3.0.0
/// @group grid
/// @access  public
/// @author Gert-Jan Meire
///
/// @param {string} $width
///   The width in columns: e.g. 3 (... of 12).
///
/// @param {string} $offset
///   The offset in columns: e.g. 3 (... of 12).
///
/// @param {string} $columns
///   The amount of columns.
///
@mixin set-layout($width: 'width-12', $offset: 'offset-0', $columns: 'columns-12') {
  width: span(number(nth(str-split($width, '-'), 2)) of number(nth(str-split($columns, '-'), 2)));

  @if $offset != 'offset-0' {
    margin-left: span(number(nth(str-split($offset, '-'), 2)) wide of number(nth(str-split($columns, '-'), 2)));
  }
  @else {
    margin-left: 0;
  }
}
