select {
  @include field-base;

  @each $theme, $colors in $themes {
    // Apply the default section as default color scheme if there is no section
    // defined in the DOM.
    .cs--#{$theme} & {
      &,
      &:focus {
        $fill-color: set-themified-property($colors, 'color-primary', 'link-color');
        $svg-shevron-down: svg-icon('shevron-down', $fill-color, 24, 24);

        padding-right: 3.8rem;
        background-image: svg-url($svg-shevron-down), url('#{$styleguide-dir}/img/svg/separator.svg');
        background-repeat: no-repeat;
        background-position: right .75rem center, right 2.8rem center;
      }
    }
  }

  &::-ms-expand { // sass-lint:disable-line no-vendor-prefixes
    display: none;
  }

  &::-ms-value { // sass-lint:disable-line no-vendor-prefixes
    @include theme('color', 'color-tertiary', 'default-text-color');
    background: none; // remove blue background on ie10/ie11 when selected
  }
}
