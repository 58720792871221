$avatar-width: 14rem;

.contact-details {
  &.highlight .inner-box, /// @deprecated
  &.highlight .highlight__inner {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    > h2 {
      width: 100%;
    }

    &::before {
      @include spot-image('book-light', 7rem);
    }
  }

  &.contact-details-inverted {
    > .inner-box, /// @deprecated
    > .highlight__inner {
      @include theme('border-color', 'color-primary--lighten-4', 'contact-details-border-color');
      border-width: 2px 0;
      border-style: solid;

      [class*="cs--"] & {
        background-color: transparent;
      }

      &::before {
        @include spot-image('book', 7rem);
      }
    }
  }

  &-column {
    flex-grow: 1;
    width: 100%;

    @include tablet {
      width: span(6 of 12);
      margin-right: $gutter-width;

      &:last-of-type:not(:first-of-type) {
        max-width: $avatar-width;
        margin-right: 0;
      }
    }

    .image-wrapper {
      img {
        @include tablet {
          max-width: $avatar-width;
        }
      }
    }
  }
}
