ul,
ol {
  position: relative;
  margin-left: 1rem;

  li,
  li > a {
    font-family: $default-font-family;
    line-height: 2.1;
  }

  &.links {
    @extend %list-no-style;
  }

  &.inline {
    @extend %list-no-style;

    display: flex;
    flex-wrap: wrap;
    margin: 0;

    li {
      width: auto;
      margin-right: $gutter-width;
    }
  }

  a {
    font-weight: normal;
  }
}

ul {
  &.checkmark-list,
  &.checkmark-list-small {
    @extend %list-no-style;

    margin-left: 0;

    li {
      @include icon('checkmark');

      position: relative;
      padding-left: 1.6em;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        margin-left: -.2em;
        font-size: 1.6rem;
        line-height: 1.1;
      }
    }
  }

  &.checkmark-list-small {
    li {
      font-size: .8rem;
      line-height: 2.4;

      &::before {
        font-size: 1.2rem;
        line-height: 1.7;
      }
    }
  }

  &.icon-list {
    margin: 0 0 2rem;
    list-style-type: none;

    &.inline {
      li {
        display: flex;
        align-items: center;
        margin-right: .7rem;
        margin-bottom: 0;

        i {
          width: auto;
          margin-right: .2rem;
        }
      }
    }

    li {
      position: relative;
      margin-bottom: .8rem;
      padding-left: 1.6rem;
      line-height: 1.5;

      &:last-child {
        margin-bottom: 0;
      }

      ul {
        margin: 0;

        li {
          margin-bottom: .2rem;
        }
      }

      a {
        padding: 0;
        line-height: inherit;

        &[href^="mailto:"]:not(.button),
        &[href^="tel:"]:not(.button),
        &[download]:not(.button),
        &[href^="http://"]:not(.button),
        &[href^="https://"]:not(.button) {
          &::after {
            display: none; // hide default link icons
          }
        }
      }

      i {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.5em;
        line-height: 1;
      }
    }
  }

  &.dash-separated-list {
    @extend %list-no-style;
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    > li:not(:last-of-type)::after {
      display: inline-block;
      padding: 0 .2em;
      content: "\2014";
    }
  }
}

dl {
  dt,
  dd {
    font-family: $default-font-family;
    line-height: 1.8;
  }

  dt {
    @include bold-text;
  }

  dd {
    margin-bottom: .4rem;
    padding-left: 1.2rem;
  }
}


///
/// Remove default styling from a list.
///
/// @since 3.0.0
/// @access public
/// @author Gert-Jan Meire
///
%list-no-style {
  margin-left: 0;
  padding-left: 0;
  list-style: none;

  p {
    font-size: .8rem;
  }

  a {
    @include bold-text;
  }
}
