a.skiplink {
  @include reset-link-background;
  position: absolute;
  bottom: 100%;
  left: 0;
  padding: .5rem 2rem;
  background-color: color('white');
  z-index: z('skiplink');

  &:focus {
    top: 0;
    bottom: auto;
    background-color: color('white');
  }
}
