input[type="text"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="date"],
input[type="time"],
input[type="tel"],
input[type="email"],
input[type="url"] {
  @include field-base;
}

input[type="password"] {
  [class*="cs--"] & {
    &,
    &:focus {
      padding-right: $field-icon-padding;
      background-image: svg-as-background('lock-closed', color('dark-gray'), 30, 26);
      background-repeat: no-repeat;
      background-position: right .75rem center;

      &:disabled {
        background-image: svg-as-background('lock-closed', color('dark-gray', -2), 30, 26);
      }
    }
  }
}

// Fixes vertical alignment on mobile safari.
input[type=date] {
  line-height: 2.4rem;
}

input[type=date]::-webkit-inner-spin-button { // sass-lint:disable-line no-vendor-prefixes
  margin-left: .5rem;
  -webkit-appearance: none;  // sass-lint:disable-line no-vendor-prefixes
}

.radio {
  @include choices-field-base('radio');

  &:hover {
    // Unchecked and hovered.
    label::before {
      @include theme('background-color', 'color-tertiary--lighten-4', 'radio-background-color-hover');
      @include theme('border-color', 'color-tertiary--lighten-1', 'radio-border-color-hover');
    }

    // Checked and hovered.
    input[type="radio"]:checked + label::before {
      @include theme('background-color', 'color-primary--lighten-3', 'radio-background-color-hover-and-checked');
      @include theme('border-color', 'color-primary--darken-3', 'radio-border-color-hover-and-checked');
      @include theme('color', 'color-primary--darken-3', 'radio-color-hover-and-checked');
    }
  }

  &:focus + label,
  input[type="radio"]:focus + label {
    @include focus-bare;
  }
}

.checkbox {
  @include choices-field-base('checkbox');

  &:hover {
    // Unchecked and hovered.
    label::before {
      @include theme('background-color', 'color-tertiary--lighten-4', 'checkbox-background-color-hover');
      @include theme('border-color', 'color-tertiary--lighten-1', 'checkbox-border-color-hover');
    }

    // Checked and hovered.
    input[type="checkbox"]:checked + label::before {
      @include theme('background-color', 'color-primary--lighten-3', 'checkbox-background-color-hover-and-checked');
      @include theme('border-color', 'color-primary--darken-2', 'checkbox-border-color-hover-and-checked');
      @include theme('color', 'color-primary--darken-3', 'checkbox-color-hover-and-checked');
    }
  }

  &:focus + label,
  input[type="checkbox"]:focus + label {
    @include focus-bare;
  }
}
