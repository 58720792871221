h1,
.h1,
%h1 {
  @include theme('color', 'color-primary', 'heading-1-color');

  font-size: 1.6rem;

  @include tablet {
    font-size: 2.1rem;
  }
}
