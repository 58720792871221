.documents,
.document-box { // Deprecated
  li {
    @include theme('border-color', 'color-primary--lighten-4', 'more-info-block-border-color');

    width: 100%;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid;

    @include tablet {
      max-width: 36rem;
    }

    &:last-of-type {
      padding-bottom: 0;
      border: 0;
    }
  }

  h3 {
    @extend %h4;
  }

  button {
    margin-top: 2.3rem;
  }

  .file-size {
    margin-left: .3em;
  }
}
