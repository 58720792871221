.summary-box {
  text-align: center;

  ul.icon-list {
    width: 100%;
    margin: 0;

    time {
      @include bold-text;
      display: block;
    }
  }

  .inner-box, /// @deprecated
  .highlight__inner {
    position: relative;
    margin-bottom: 1.5rem; // Save room for the accolade
    text-align: left;

    &::before {
      top: -3.5rem;
    }

    .accolade {
      bottom: -1rem;
      background-color: inherit;

      &::before,
      &::after {
        @include theme('background-color', 'color-secondary', 'summary-box-accolade-background');
      }
    }
  }

  .button {
    @include button-large;
  }
}
