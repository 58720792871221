.contact-block {

  &.tel .inner-box::before, /// @deprecated
  &.tel .highlight__inner::before {
    @include spot-image('phone-light', 7rem);
  }

  &.mail .inner-box::before, /// @deprecated
  &.mail .highlight__inner::before {
    @include spot-image('mail-light', 7rem);
  }

  .inner-box, /// @deprecated
  .highlight__inner {
    li:last-of-type {
      margin-top: 1rem;
    }
  }
}
