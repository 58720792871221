%messages,
.messages {
  @include theme('color', 'color-tertiary--lighten-1', 'status-messages-color');

  $status-message-icon-size: 2.1rem;

  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  max-width: 40rem;
  margin-top: 1rem;
  margin-right: 0;
  margin-bottom: 1rem;
  padding: 1rem 1.2rem;
  border-radius: border-radius('radius-1');
  font-size: .7rem;
  line-height: 1.43;
  text-align: center;

  i {
    display: block;
    flex: 0 0 $status-message-icon-size;
    width: $status-message-icon-size;
    margin-right: 0;
    margin-bottom: 1rem;
    border-radius: 100%;
    background-color: color('white');
    font-size: 1.5rem;
    line-height: $status-message-icon-size;
    text-align: center;

    @include phablet {
      align-self: flex-start;
    }
  }

  @include phablet {
    flex-direction: row;
    align-items: center;
    text-align: initial;

    i {
      margin-right: 1.2rem;
      margin-bottom: 0;
    }
  }

  &.messages--status {
    background-color: color('green', -4);

    i {
      color: color('green');
    }
  }

  &.messages--error {
    background-color: color('red', -4);

    i {
      color: color('red');
    }
  }

  &.messages--warning {
    background-color: color('orange', -4);

    i {
      color: color('red');
    }
  }

  p {
    margin: 0;
  }

  a {
    @include theme('border-color', 'color-tertiary', 'status-messages-link-border-color');

    padding: .1em 0;
    color: inherit;
  }
}
