.table-of-contents {
  margin-bottom: 2rem;

  ul > li {
    display: block;
    width: 100%;
    margin-right: 0;

    @include phablet {
      display: inline-block;
      width: auto;
    }

    &::after {
      @include phablet {
        @include theme('color', 'color-primary--lighten-3', 'filter-nav-longdash-color');

        display: inline-block;
        padding: 0 .2rem;
        content: '—';
      }
    }

    &:last-child::after {
      content: '';
    }
  }
}
