time[pubdate] {
  @include large-text;

  display: block;

  h1 + & {
    margin-top: -1em;
    margin-bottom: 1em;
  }
}
