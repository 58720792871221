.video {
  @include clearfix;
  width: 100%;

  .responsive-video {
    position: relative;
    padding-bottom: calc(100% / (16/9));

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  a {
    margin-top: .4rem;
    margin-bottom: .4rem;
    float: right;
  }
}
