// sass-lint:disable no-important

// For anything you want to hide on page load when JS is enabled, so
// that you can use the JS to control visibility and avoid flicker.
html.js .js-hide {
  display: none;
}

/// Hide elements from all users.
///
/// Used for elements which should not be immediately displayed to any user. An
/// example would be a collapsible fieldset that will be expanded with a click
/// from a user. The effect of this class can be toggled with the jQuery show()
/// and hide() functions.
.element-hidden {
  display: none;
}

/// Hide elements visually, but keep them available for screen-readers.
///
/// Used for information required for screen-reader users to understand and use
/// the site where visual display is undesirable. Information provided in this
/// manner should be kept concise, to avoid unnecessary burden on the user.
/// '!important' is used to prevent unintentional overrides.
.element-invisible {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

// The .element-focusable class extends the .element-invisible class to allow
// the element to be focusable when navigated to via the keyboard.
.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  position: static !important;
  height: auto;
  overflow: visible;
  clip: auto;
}
