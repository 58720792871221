////
/// This is an generated file! Do not change this file. Use the template file
/// _icons_template.template instead to apply changes.
///
/// @group tools
/// @author Gert-Jan Meire
///
////

$id: random(1000);

@font-face {
  font-family: "gent-icons";
  src: url('#{$styleguide-dir}/fonts/gent-icons.eot?v=#{$id}');
  src: url('#{$styleguide-dir}/fonts/gent-icons.eot?#iefix?v=#{$id}') format('eot'),
  url('#{$styleguide-dir}/fonts/gent-icons.woff2?v=#{$id}') format('woff2'),
  url('#{$styleguide-dir}/fonts/gent-icons.woff?v=#{$id}') format('woff'),
  url('#{$styleguide-dir}/fonts/gent-icons.ttf?v=#{$id}') format('truetype'),
  url('#{$styleguide-dir}/fonts/gent-icons.svg#gent-icons?v=#{$id}') format('svg');
}

@mixin icon-styles {
  font-family: "gent-icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

[class*='icon-'],
%icon {
  @include icon-styles;
}

///
/// A function to return the correct character string.
///
/// @since 3.0.0
/// @group tools
/// @access private
/// @author Gert-Jan Meire
///
/// @param {string} $filename
///   The name of the icon.
///
@function icon-char($filename) {
  $char: "";
  
    @if $filename == arrow-down {
      $char: "\E001";
    }
    @if $filename == arrow-left {
      $char: "\E002";
    }
    @if $filename == arrow-right {
      $char: "\E003";
    }
    @if $filename == arrow-up {
      $char: "\E004";
    }
    @if $filename == broken-link {
      $char: "\E005";
    }
    @if $filename == calendar {
      $char: "\E006";
    }
    @if $filename == chat-round {
      $char: "\E007";
    }
    @if $filename == chat {
      $char: "\E008";
    }
    @if $filename == checkmark-checkbox {
      $char: "\E009";
    }
    @if $filename == checkmark-circle {
      $char: "\E00A";
    }
    @if $filename == checkmark {
      $char: "\E00B";
    }
    @if $filename == chevron-down {
      $char: "\E00C";
    }
    @if $filename == chevron-left {
      $char: "\E00D";
    }
    @if $filename == chevron-right {
      $char: "\E00E";
    }
    @if $filename == chevron-up {
      $char: "\E00F";
    }
    @if $filename == clock {
      $char: "\E010";
    }
    @if $filename == cross {
      $char: "\E011";
    }
    @if $filename == digipolis {
      $char: "\E012";
    }
    @if $filename == document {
      $char: "\E013";
    }
    @if $filename == download {
      $char: "\E014";
    }
    @if $filename == envelope {
      $char: "\E015";
    }
    @if $filename == euro {
      $char: "\E016";
    }
    @if $filename == exclamation-circle {
      $char: "\E017";
    }
    @if $filename == exclamation {
      $char: "\E018";
    }
    @if $filename == external-link {
      $char: "\E019";
    }
    @if $filename == facebook {
      $char: "\E01A";
    }
    @if $filename == fancyback {
      $char: "\E01B";
    }
    @if $filename == favicon {
      $char: "\E01C";
    }
    @if $filename == fax {
      $char: "\E01D";
    }
    @if $filename == filter {
      $char: "\E01E";
    }
    @if $filename == flickr {
      $char: "\E01F";
    }
    @if $filename == globe {
      $char: "\E020";
    }
    @if $filename == googleplus {
      $char: "\E021";
    }
    @if $filename == hamburger {
      $char: "\E022";
    }
    @if $filename == heart {
      $char: "\E023";
    }
    @if $filename == home {
      $char: "\E024";
    }
    @if $filename == image {
      $char: "\E025";
    }
    @if $filename == instagram {
      $char: "\E026";
    }
    @if $filename == lightning {
      $char: "\E027";
    }
    @if $filename == linkedin {
      $char: "\E028";
    }
    @if $filename == lock-closed {
      $char: "\E029";
    }
    @if $filename == lock-open {
      $char: "\E02A";
    }
    @if $filename == logo-en {
      $char: "\E02B";
    }
    @if $filename == logo-es {
      $char: "\E02C";
    }
    @if $filename == logo-fr {
      $char: "\E02D";
    }
    @if $filename == logo-nl {
      $char: "\E02E";
    }
    @if $filename == logo-sp {
      $char: "\E02F";
    }
    @if $filename == marker {
      $char: "\E030";
    }
    @if $filename == minus {
      $char: "\E031";
    }
    @if $filename == normalphone {
      $char: "\E032";
    }
    @if $filename == phone {
      $char: "\E033";
    }
    @if $filename == plus {
      $char: "\E034";
    }
    @if $filename == readspeaker {
      $char: "\E035";
    }
    @if $filename == search {
      $char: "\E036";
    }
    @if $filename == share {
      $char: "\E037";
    }
    @if $filename == size {
      $char: "\E038";
    }
    @if $filename == star-empty {
      $char: "\E039";
    }
    @if $filename == star-full {
      $char: "\E03A";
    }
    @if $filename == star-half {
      $char: "\E03B";
    }
    @if $filename == twitter-outline {
      $char: "\E03C";
    }
    @if $filename == twitter {
      $char: "\E03D";
    }
    @if $filename == update {
      $char: "\E03E";
    }
    @if $filename == url {
      $char: "\E03F";
    }
    @if $filename == user {
      $char: "\E040";
    }
    @if $filename == users {
      $char: "\E041";
    }
    @if $filename == vimeo {
      $char: "\E042";
    }
    @if $filename == wheelchair {
      $char: "\E043";
    }
    @if $filename == youtube {
      $char: "\E044";
    }

    @return $char;
}

///
/// A function to render an icon before or after an element.
/// It can also be used as a placeholder instead of a mixin.
/// NOTE: This is a automatically generated function!
///
/// @since 3.0.0
/// @group tools
/// @access public
/// @author Gert-Jan Meire
/// @require icon-char
///
/// @param {string} $filename
///   The name of the icon.
///
/// @param {string} $insert [before]
///   Wether the icon should be printed before or after the element.
///
/// @param {boolean} colors$ [$true]
///   Should the item be a placeholder @extends or not.
///
@mixin icon($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %icon;
    } @else {
    @include icon-styles;
    }
    content: icon-char($filename);
  }
}

.icon-arrow-down {
  @include icon(arrow-down);
}
.icon-arrow-left {
  @include icon(arrow-left);
}
.icon-arrow-right {
  @include icon(arrow-right);
}
.icon-arrow-up {
  @include icon(arrow-up);
}
.icon-broken-link {
  @include icon(broken-link);
}
.icon-calendar {
  @include icon(calendar);
}
.icon-chat-round {
  @include icon(chat-round);
}
.icon-chat {
  @include icon(chat);
}
.icon-checkmark-checkbox {
  @include icon(checkmark-checkbox);
}
.icon-checkmark-circle {
  @include icon(checkmark-circle);
}
.icon-checkmark {
  @include icon(checkmark);
}
.icon-chevron-down {
  @include icon(chevron-down);
}
.icon-chevron-left {
  @include icon(chevron-left);
}
.icon-chevron-right {
  @include icon(chevron-right);
}
.icon-chevron-up {
  @include icon(chevron-up);
}
.icon-clock {
  @include icon(clock);
}
.icon-cross {
  @include icon(cross);
}
.icon-digipolis {
  @include icon(digipolis);
}
.icon-document {
  @include icon(document);
}
.icon-download {
  @include icon(download);
}
.icon-envelope {
  @include icon(envelope);
}
.icon-euro {
  @include icon(euro);
}
.icon-exclamation-circle {
  @include icon(exclamation-circle);
}
.icon-exclamation {
  @include icon(exclamation);
}
.icon-external-link {
  @include icon(external-link);
}
.icon-facebook {
  @include icon(facebook);
}
.icon-fancyback {
  @include icon(fancyback);
}
.icon-favicon {
  @include icon(favicon);
}
.icon-fax {
  @include icon(fax);
}
.icon-filter {
  @include icon(filter);
}
.icon-flickr {
  @include icon(flickr);
}
.icon-globe {
  @include icon(globe);
}
.icon-googleplus {
  @include icon(googleplus);
}
.icon-hamburger {
  @include icon(hamburger);
}
.icon-heart {
  @include icon(heart);
}
.icon-home {
  @include icon(home);
}
.icon-image {
  @include icon(image);
}
.icon-instagram {
  @include icon(instagram);
}
.icon-lightning {
  @include icon(lightning);
}
.icon-linkedin {
  @include icon(linkedin);
}
.icon-lock-closed {
  @include icon(lock-closed);
}
.icon-lock-open {
  @include icon(lock-open);
}
.icon-logo-en {
  @include icon(logo-en);
}
.icon-logo-es {
  @include icon(logo-es);
}
.icon-logo-fr {
  @include icon(logo-fr);
}
.icon-logo-nl {
  @include icon(logo-nl);
}
.icon-logo-sp {
  @include icon(logo-sp);
}
.icon-marker {
  @include icon(marker);
}
.icon-minus {
  @include icon(minus);
}
.icon-normalphone {
  @include icon(normalphone);
}
.icon-phone {
  @include icon(phone);
}
.icon-plus {
  @include icon(plus);
}
.icon-readspeaker {
  @include icon(readspeaker);
}
.icon-search {
  @include icon(search);
}
.icon-share {
  @include icon(share);
}
.icon-size {
  @include icon(size);
}
.icon-star-empty {
  @include icon(star-empty);
}
.icon-star-full {
  @include icon(star-full);
}
.icon-star-half {
  @include icon(star-half);
}
.icon-twitter-outline {
  @include icon(twitter-outline);
}
.icon-twitter {
  @include icon(twitter);
}
.icon-update {
  @include icon(update);
}
.icon-url {
  @include icon(url);
}
.icon-user {
  @include icon(user);
}
.icon-users {
  @include icon(users);
}
.icon-vimeo {
  @include icon(vimeo);
}
.icon-wheelchair {
  @include icon(wheelchair);
}
.icon-youtube {
  @include icon(youtube);
}
