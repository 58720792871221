.mijn-gent-block {
  display: flex;
  position: relative;
  justify-content: flex-end;

  .avatar {
    @include bold-text;

    flex-shrink: 0;
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 50%;
    background-color: #29cfc9;
    color: #fff;
    font-size: 1.3rem;
    line-height: 2.3rem;
    text-align: center;
    text-transform: uppercase;
  }

  .login-link {
    text-overflow: ellipsis;
    overflow: hidden;

    &::after {
      content: none;
    }
  }

  img {
    border-radius: 50%;
  }

  .accordion--button {
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    font-size: .8rem;
    cursor: pointer;

    * {
      vertical-align: middle;
    }

    img {
      width: 2.3rem;
      height: 2.3rem;
      object-fit: cover;
    }

    span {
      @include bold-text;

      display: none;
      padding: 0 .5em;

      @include tablet {
        display: inline-block;
      }
    }

    &::before {
      line-height: 0;
    }

    span.avatar {
      display: block;
    }
  }

  .accordion--content {
    @include theme('box-shadow', 'color-box-shadow-secondary-dark');

    position: absolute;
    top: 100%;
    right: 0;
    width: calc(100vw - 2 * #{$gutter-width});
    max-width: $bp-mobile;
    margin-top: .5rem;
    transition: max-height .3s ease-in-out;
    border-radius: border-radius('radius-1');
    background: color('white');
    z-index: z('mijn-gent');
  }

  .content {
    padding: 1.2rem;

    h2 {
      @extend %h3;
    }

    > h2 {
      margin-bottom: 0;
    }

    section {
      @include theme('border-color', 'color-primary--lighten-4', 'mijn_gent-border-color');

      padding: .6rem 0;
      border-top: 2px solid;

      .links {
        margin: 0;

        ///
        /// All links shouldn't have an indicator.
        ///
        a {
          @include reset-link-background;
          @include link-underlined;
        }
      }
    }

    .profile {
      display: flex;
      padding-bottom: 1rem;
      border-top: 0;

      &-info {
        span {
          display: block;
          font-size: .7rem;
        }
      }

      img {
        align-self: flex-start;
        width: 3.3rem;
        height: 3.3rem;
        margin-right: $gutter-width;
        object-fit: cover;
      }

      .avatar {
        width: 3.3rem;
        height: 3.3rem;
        margin-right: 1.2rem;
        line-height: 3.3rem;
      }
    }
  }
}
