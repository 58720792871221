.help-block {
  @include theme('background-color', 'color-primary--lighten-5', 'search-block-background');

  ul {
    margin: 0;
    list-style-type: none;

    @include tablet {
      margin-left: auto;
    }

    li {
      position: relative;
      margin-bottom: .5rem;
      line-height: 1.8;

      &,
      > * {
        font-size: .8rem;
      }
    }
  }

  &-section {
    max-width: $six-of-bp-container;
    margin-right: auto;
    margin-bottom: 2rem;

    &:last-of-type {
      margin: 0;
    }
  }

  .search {
    @include desktop {
      width: span(7 of 9);
    }
  }
}
