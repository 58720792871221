.dg-block-disclaimer {

  h2 {
    @extend %h3;
  }

  ul {
    margin: 0;
    list-style: none;
  }
}
