.form-steps,
.wizard-steps { /// @deprecated
  ol.form-steps-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    counter-reset: item;
    list-style-type: none;

    li {
      display: inline-block;
      margin-bottom: .8rem;

      &:not(:first-child):not(:last-child):not(.active) {
        @include desktop-and-below {
          // clip
          position: absolute;
          width: 1px;
          height: 1px;
          margin: -1px;
          padding: 0;
          border: 0;
          overflow: hidden;
          // also hide from screenreaders
          visibility: hidden;
          clip: rect(0 0 0 0);
        }
      }

      span > span,
      a > span {
        @include desktop-and-below {
          position: absolute;
          width: 1px;
          height: 1px;
          margin: -1px;
          padding: 0;
          border: 0;
          overflow: hidden;
          clip: rect(0 0 0 0);
        }
      }

      a {
        @include reset-link-background;
        padding: 0;
        vertical-align: middle;

        &:hover,
        &:focus {
          background-color: transparent;

          &::before {
            @include theme('border-color', 'color-primary--darken-1', 'form-step--active-border-color');
          }
        }

        > span {
          @include link-background('color-primary', null, 'link-background-color');
        }
      }

      a::before,
      > span::before {
        @include theme('border-color', 'color-primary--lighten-5', 'form-step--active-border-color');
        @include theme('background-color', 'color-primary--lighten-5', 'form-step--active-border-color');
        @include theme('color', 'color-tertiary', 'default-text-color');
        @include bold-text;

        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        margin-right: .8rem;
        transition: .2s ease-in-out;
        border: 2px solid;
        border-radius: 1rem;
        font-size: .8rem;
        content: counter(item);
        counter-increment: item;
      }

      &.active {
        @include bold-text;

        > span::before {
          @include theme('border-color', 'color-primary--darken-1', 'form-step--active-border-color');

          background-color: transparent;
        }
      }

      &.completed:not(.active) {
        a,
        > span {
          @include icon(checkmark, before);
        }

        a::before,
        > span::before {
          border-color: color('green', -3);
          background-color: color('green', -3);
          font-size: 1.5rem;
          line-height: 1.25;
          vertical-align: middle;
        }

        a:hover,
        a:focus {
          &::before {
            border-color: color('green', 1);
          }
        }
      }

      @include desktop-and-below {
        &.active {
          margin-bottom: 0;

          ::before,
          ::after {
            margin-bottom: .8rem;
          }

          &::before,
          &::after {
            @include theme('border-color', 'color-primary--darken-2', 'form-step-hellip-background-color');
            @include theme('background-color', 'color-primary--darken-2', 'form-step-hellip-background-color');
            @include theme('color', 'color-secondary', 'form-step-hellip-color');

            display: inline-block;
            width: 2rem;
            height: 2rem;
            margin-right: .8rem;
            margin-bottom: .8rem;
            border: 2px solid;
            border-radius: 1rem;
            text-align: center;
            content: '\2026';
          }
        }

        &:first-child::before,
        &:nth-child(2)::before,
        &:nth-last-child(2)::after,
        &:last-child::after {
          content: none;
        }
      }
    }
  }

  &:not(.vertical) ol.form-steps-list {
    @include desktop {
      li:not(:last-child)::after {
        @include theme('border-color', 'color-primary--lighten-3', 'form-step--active-border-color');

        display: inline-block;
        width: .8rem;
        margin: 0 .6rem;
        border-bottom: 2px solid;
        content: '';
        vertical-align: middle;
      }
    }
  }

  &.vertical ol.form-steps-list {
    @include desktop {
      flex-direction: column;

      li {
        margin-bottom: .6rem;

        &:not(:last-child)::after {
          @include theme('border-color', 'color-primary--lighten-3', 'form-step--active-border-color');

          display: block;
          width: 1rem;
          height: .8rem;
          margin: .6rem 0 0;
          border-right: 2px solid;
          content: '';
        }
      }
    }
  }
}
