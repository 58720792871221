.form--multistep {
  @include desktop {
    display: flex;

    >.form-content {
      @include set-layout('width-9');
    }

    >.form-steps {
      @include set-layout('width-3');
      margin-right: gutter();
    }
  }
}
