.form-item {
  margin-bottom: 1rem;

  > * {
    max-width: 20.5rem;

    @include desktop {
      max-width: 41rem;

      &.field-message {
        max-width: 20.5rem;
      }
    }
  }

  .field-message:not(.error):not(.success) {
    margin-bottom: .4rem;
  }

  &:not(.stacked) {
    .form-columns {
      margin-bottom: 0;

      @include desktop {
        display: flex;

        .form-item-column {
          width: 50%;

          &:last-child {
            display: flex;
            align-items: flex-start;

            .field-message {
              margin: 0 0 gutter()/2 .2rem;
              padding: .7rem 1.2rem .7rem 3.9rem;

              &::before {
                top: .5rem;
                left: 1.7rem;
              }

              .accolade {
                right: auto;
                bottom: 0;
                left: 0;
                width: 1rem;
                height: 100%;
                transform: rotate(0deg);

                &::before,
                &::after {
                  width: 1rem;
                  border: 0;
                }

                &::before {
                  top: 0;
                  bottom: 1.2rem;
                  height: 1.2rem;
                  border-radius: 0 0 border-radius("radius-4");
                }

                &::after {
                  top: 1.2rem;
                  right: 0;
                  bottom: -1px;
                  height: auto;
                  border-radius: 0 border-radius("radius-4") 0 0;
                }
              }
            }
          }
        }
      }
    }
  }

  &.stacked {
    > * {
      max-width: none;
    }

    .form-columns {
      flex-wrap: wrap;
      margin-top: auto;
    }

    .form-columns .form-item-column {
      width: 100%;
      max-width: 40rem;

      .field-message {
        margin-top: .2rem;
        margin-left: 0;
      }
    }
  }
}
