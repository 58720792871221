.db-block-partnership {
  display: flex;
  align-items: center;
  font-size: .7rem;

  p,
  i.icon-digipolis {
    margin: 0;
    line-height: 1rem;
  }

  i.icon-digipolis {
    margin-left: .5rem;
    font-size: 1.1rem;
  }
}
